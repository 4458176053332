import {css} from 'lit';
import {tablet, desktop} from '../../js/services/const';

export const navigationStyles = css`
  :host(.open) {
    display: block;
  }

  :host {
    display: none;
    transition: height 0.25s;
    flex-direction: column;
    width: 100%;
    height: var(--header-height);
    position: fixed;
    top: 0;
    left: 0;
    z-index: calc(var(--header-z-index) + 10);
    box-shadow: 8px 0 8px -8px rgba(0, 0, 0, 0.5);
    background: var(--white);
  }

  .nav {
    height: 100%;
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
}

  .nav li a {
    color: var(--text-highVisibility);
    font-weight: inherit;
    font-size: 1.8rem;
  }

  .nav li.lvlone__item a:hover {
    color: var(--system-hyperlink);
  }

  .nav li.lvltwo__item a:hover {
    text-decoration: underline;
    color: inherit;
  }

  .nav li a::selection {
    background-color: transparent;
  }

  .nav .nav__header {
    height: var(--header-height);
    display: flex;
    flex: 0 0 var(--header-height);
    border-top: var(--nav-header-border-top);
  }

  .nav .navi {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    flex: 1;
    position: relative;
    padding-top: 1.6rem;
    transition: padding-top 0.3s 0.25s;
    height: calc(100% - var(--header-height) * 1.6);
  }

  .nav .navi li {
    list-style: none;
  }

  .nav .lvlone__list {
    opacity: 0;
    transition: opacity 0.3s 0.25s;
  }

  .nav .lvlone__list.attop .lvlone__item.topline {
    visibility: hidden;
  }

  .nav .lvlone__item {
    margin: 0 5rem;
  }

  .nav .lvlone__item::after {
    content: '';
    margin: 0 auto;
    width: 10%;
    height: 1px;
    background-color: var(--separator-default);
    display: block;
    transition: width 0.4s 0.4s;
  }

  .nav .lvlone__item.topline {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .nav .lvlone__item:last-child::after {
    display: none;
  }

  .nav .lvlone__item a {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 0;
  }

  .nav .lvlone__item--haslist .lvltwo__header .icon-arrow-down {
    display: inline-block;
  }

  .nav .lvlone__item--show {
    border-bottom: 0;
  }

  .nav .lvlone__item--show .lvltwo__list {
    display: block;
  }

  .nav .lvlone__item--show .lvltwo__header .icon-arrow-down {
    transform: rotate(180deg);
  }

  .nav .lvltwo__list {
    display: none;
    margin: 1.5rem 0;
    padding: 0;
  }

  .nav .lvltwo__item .icon {
    display: none;
  }

  .nav .lvltwo__item--haslist .icon-arrow-down {
    display: inline-block;
  }

  .nav .lvltwo__item--haslist .lvlthree__list {
    display: none;
  }

  .nav .lvltwo__item--show .lvlthree__list {
    display: block;
  }

  .nav .lvltwo__item--show .lvlthree__header .icon-arrow-down {
    transform: rotate(180deg);
  }

  .nav .lvltwo__item a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  }

  .nav .lvltwo__header {
    font-weight: bold;
    min-width: 0;
    padding: 1rem 0;
    display: flex;
    width: 100%;
    height: 4rem;
    box-sizing: border-box;
  }

  .nav .header-spacer {
    flex: 1;
    line-height: 2rem;
  }

  .nav .icon-arrow-down {
    display: none;
    padding: 0.5rem;
    transition: transform 0.2s;
    font-size: 1.5rem;
    color: var(--text-lowVisibility);
    cursor: pointer;
    align-self: center;
  }

  .nav .lvlthree__header {
    width: 100%;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    height: 4rem;
    box-sizing: border-box;
  }

  .nav .lvlthree__list {
    width: 100%;
    margin: 1rem 0;
  }

  .nav .lvlthree__item {
    width: 100%;
    margin-left: 2rem;
    padding: 1rem 0;
  }

  .nav .lvlthree__item a:hover {
    background: none;
  }

  .nav .lvlthree__item--partneroffers {
    color: var(--cp-grey-light-6);
    cursor: default;
  }

  :host footer {
    display: none;
    color: var(--separator-default);
    font-size: 1.2rem;
    line-height: 1.6rem;
    padding: 0 0 0.8rem 1rem;
  }

  :host footer a {
    color: var(--text-lowVisibility);
  }

  :host footer a:hover {
    color: var(--system-hyperlink);
  }

  :host(.nav-ani) {
    height: 100%;
  }

  :host(.nav-ani) footer {
    display: block;
    position: relative;
    overflow: visible;
  }

  :host(.nav-ani) footer::before {
    content: '';
    position: absolute;
    top: calc(var(--gap) * -1);
    left: 0;
    width: 100%;
    height: var(--gap);
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }

  :host(.nav-ani) .navi {
    padding-top: 0;
  }

  :host(.nav-ani) .lvlone__list {
    opacity: 1;
  }

  :host(.nav-ani) .lvlone__item::after {
    width: 100%;
  }

  @media (min-width: ${tablet}px) {
    :host {
      width: 33.6rem;
    }
  }

  @media (min-width: ${desktop}px) {
    :host .nav li a {
      font-size: 1.6rem;
    }
  }

  .icon {
    font-family: icons;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    font-size: 3.2rem;
    line-height: 100%;
  }

  .icon-arrow-down::before {
    content: '\\e607';
  }

  .new-header__logo {
    display: flex;
    flex: 0 0;
    flex-basis: 3.2rem;
  }

  .new-header__hamburger {
    flex: 0 0 5rem;
  }

  .new-header__navtrigger {
    display: flex;
    float: none;
    line-height: 0;
    width: 100%;
    height: 100%;
    outline: 0;
    cursor: pointer;
  }

  :host(.open) .burger-icon::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s ease-out 0.12s;
  }

  :host(.open) .burger-icon {
    transform: rotate(225deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  :host(.open) .burger-icon::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out,
      transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .burger-icon {
    display: block;
    position: relative;
    margin: auto 16px;
    width: 18px;
    height: 2px;
    transition-property: transform;
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    background-color: var(--burger-icon-bg)
  }

  .burger-icon::after {
    bottom: -6px;
    transition: bottom 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }


  .burger-icon::before {
    top: -6px;
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
  }


  .burger-icon::before,
  .burger-icon::after {
    content: '';
    position: absolute;
    display: block;
    width: 18px;
    height: 2px;
    background-color: var(--burger-icon-bg);
  }

  .new-header__logo {
    display: flex;
    flex: 0 0;
    flex-basis: var(--navigationLogoFlexBasis);
  }

  .new-header__logo .logo-link {
    display: block;
    width: 100%;
  }

  .new-header__logo .logo-image {
    display: block;
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-image: var(--navigationLogoUrl);
    background-size: var(--navigationLogoSize);
    width: 100%;
    height: 100%;
  }
`;
